import React from "react";
import HighlightIcon from '@mui/icons-material/Highlight';
import EditNoteIcon from '@mui/icons-material/EditNote';

function Header(){
    return <header>
        
        <h1><EditNoteIcon style={{
            fontSize: 40,
            marginRight: 10,
            // paddingTop: 1
        }} />Note Verse</h1>
    </header>
}


export default Header;